import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const HomeView = () => import('@/views/HomeView.vue')

/** LOGIN */
const Login = () => import('@/pages/Login')
const NotFound = () => import('@/pages/Page404')

/** PROFILE */
const Profile = () => import('@/views/profile/index.vue')

/** ACCESS */
const Permission = () => import('@/views/access/permission.vue')
const Roles = () => import('@/views/access/roles.vue')
const Users = () => import('@/views/access/users.vue')
// const AssignedPermission = () => import('@/views/access/assignedpermission.vue')

/** PROCESOS */
// const Coti = () => import('@/views/proccess/coti.vue')
// const Order = () => import('@/views/proccess/order.vue')


Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: '/login',
    name: '',
    component: {
      render(c) { return c('router-view') } //componente
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
    ]
  },
  {
    path: '/perfil',
    redirect: '/perfil',
    name: 'Mi perfil',
    component: TheContainer, // montar componente
    children: [
      {
        path: '/',
        name: 'Mis datos',
        component: Profile
      },
    ]
  },
  {
    path: '/home',
    redirect: '/home',
    name: 'Escritorio',
    component: TheContainer, // montar componente
    children: [
      {
        path: '/',
        name: 'Inicio',
        component: HomeView
      },
    ]
  },
  {
    path: '/accesos',
    redirect: '/accesos/permisos',
    name: 'Accesos ',
    component: TheContainer,
    children: [
      {
        path: '/accesos/permisos',
        name: 'Permisos de usuario',
        component: Permission
      },
      {
        path: '/accesos/tipos',
        name: 'Roles de usuario',
        component: Roles
      },
      {
        path: '/accesos/usuarios',
        name: 'Usuarios',
        component: Users
      },
      // {
      //   path: '/accesos/permisos_asignados',
      //   name: 'Permisos Asignados',
      //   component: AssignedPermission
      // },
    ]
  },
  // {
  //   path: '/proccess',
  //   redirect: '/proccess/permisos',
  //   name: 'Procesos ',
  //   component: TheContainer,
  //   children: [
  //     {
  //       path: '/proccess/coti',
  //       name: 'Cotizaciones',
  //       component: Coti
  //     },
  //     {
  //       path: '/proccess/order',
  //       name: 'Ordenes de compra',
  //       component: Order
  //     },
  //   ]
  // },
  { path: '*', component: NotFound }
];

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
});

// router.beforeEach((to, from, next) => {
//   if(store.getters.get__token != null){
//     next()
//     return;
//   }else{
//     next('login')
//     return;
//   }
// });

// export default new Router({
//   mode: 'hash', // https://router.vuejs.org/api/#mode
//   linkActiveClass: 'open active',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: routes
// })
export default router;
