import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import * as actions from "./actions";
import createPersistedState from 'vuex-persistedstate'

const state = {
  /** template */
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  /** api */
  //url: "http://canchitas-back.test/api/v1", //local
  url: "https://iareservagol-back.howertpi.com/api/v1", // nube howert
  /** auth */
  user: {
    id: null,
    name: null,
    email: null,
    roles: [],
    companies:[],
    permissions: [],
    token:null
  },
  companies:[],
  // employees:[],
  campus:[],
  roles:[],
  company: null,
  campu:null,
  rol:null,
};

const getters = {
  get__url(state) {
    return state.url;
  },
  get__user_id(state) {
    return state.user.id;
  },
  get__user_name(state) {
    return state.user.name;
  },
  get__token(state){
    return state.user.token
  },
  get__permissionxrol(state){
    return state.user.permissions
  },
  get_all_user(state){
    return state.user
  },
  get_roles(state){
    return state.roles
  },
  get_campus(state){
    return state.campus;
  },
  get_campu(state){
    return state.campu;
  },
  get_companies(state){
    return state.companies;
  },
  get_company(state){
    return state.company;
  },
  get_rol(state){
    return state.rol;
  },
  // get_employee(state){
  //   return state.employee;
  // },
};

const mutations = {
  /** template */
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  /** auth */
  mt_set_auth(state,payload) {
    state.user = payload;
  },
  mt_logout_auth(state){
    state.user= {
      id: null,
      name: null,
      email: null,
      roles: [],
      permissions: [],
      token:null
    };
    state.companies=[];
    state.campus=[];
    state.roles=[];
    state.campu=null;
    state.company=null;
    state.rol = null;
  },
  mt_set_dataroles(state,payload){
    state.roles = payload;
  },
  mt_set_datacampus(state,payload){
    state.campus = payload;
  },
  mt_set_campu(state,payload){
    state.campu = payload;
  },
  mt_set_datacompanies(state,payload){
    state.companies = payload;
  },
  mt_set_company(state,payload){
    state.company = payload;
  },
  mt_set_rol(state,payload){
    state.rol = payload;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
        reducer: (persistedState) => {
            const stateFilter = JSON.parse(JSON.stringify(persistedState));
            return stateFilter;
        }
    })
  ]
});
